export const config = {
	Auth: {
		// identityPoolId: '',
		// identityPoolRegion: 'us-west-2',
		region: 'us-west-2',
		userPoolId: 'us-west-2_QyeYKV2Zy',
		userPoolWebClientId: '3lhsmg4932s8c7vesihfr2ause',
		madatorySignIn: true,
		authenticationFlowType: 'USER_SRP_AUTH',
	},
};

export default config;
