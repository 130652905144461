import { Semester, Course, CourseItem } from './index';

const Math100Items: CourseItem[] = [
	{
		id: 'c234t2m7',
		name: 'Assignment 1',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 10,
		grade: { numerator: 9, denominator: 10 },
	},
	{
		id: '3mu824mp',
		name: 'Assignment 2',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: { numerator: 8, denominator: 10 },
		dueDate: new Date('January 20, 2021'),
	},
	{
		id: 'n8uc9pm0',
		name: 'Assignment 3',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		dueDate: new Date('February 16, 2021'),
	},
	{
		id: 'm893lwef',
		name: 'Midterm 1',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 20,
	},
	{
		id: 'k329f7d9',
		name: 'Midterm 2',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 20,
	},
	{
		id: 'io42fj4n',
		name: 'Final',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 40,
	},
];

const MATH100: Course = {
	id: '84jvkq11',
	name: 'Math 100',
	owner: 'Person A',
	createdAt: new Date(),
	updatedAt: new Date(),
	courseItems: Math100Items,
	desiredGrade: 80,
};

const Math101Items: CourseItem[] = [
	{
		id: '419f20ac',
		name: 'Assignment 1',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 10,
		grade: { numerator: 9, denominator: 10 },
	},
	{
		id: '54e27303',
		name: 'Assignment 2',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: { numerator: 8, denominator: 10 },
		dueDate: new Date('October 20, 2021'),
	},
	{
		id: 'ne95786e7',
		name: 'Assignment 3',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		dueDate: new Date('November 16, 2021'),
	},
	{
		id: 'ff86fcaa',
		name: 'Midterm 1',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 20,
	},
	{
		id: 'bcfd4253',
		name: 'Midterm 2',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 20,
	},
	{
		id: 'a066e02b',
		name: 'Final',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 40,
	},
];

const MATH101: Course = {
	id: '725ad466',
	name: 'Math 101',
	owner: 'Person A',
	createdAt: new Date(),
	updatedAt: new Date(),
	courseItems: Math101Items,
	desiredGrade: 70,
};

const CSC110Items: CourseItem[] = [
	{
		id: 'vy8amquo',
		name: 'Assignment 1',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: { numerator: 9, denominator: 10 },
	},
	{
		id: 'jb5wy7jx',
		name: 'Assignment 2',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: 75,
	},
	{
		id: 'bmvnno73',
		name: 'Assignment 3',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: 80,
	},
	{
		id: 'bnyjauol',
		name: 'Assignment 4',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: 85,
	},
	{
		id: 'g0p3p59m',
		name: 'Assignment 5',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: { numerator: 3, denominator: 4 },
	},
	{
		id: 'oxo34929',
		name: 'Assignment 6',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
	},
	{
		id: 'ybccq9iq',
		name: 'Assignment 7',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
	},
	{
		id: '8j6dxeye',
		name: 'Assignment 8',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
	},
	{
		id: 'xbovaqnv',
		name: 'Midterm 1',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 20,
		grade: 60,
	},
	{
		id: 'v40rpo7y',
		name: 'Midterm 2',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 20,
		grade: 85,
	},
	{
		id: 'j1no3ws6',
		name: 'Midterm 3',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 20,
	},
];

const CSC110: Course = {
	id: 'ynlq4qre',
	name: 'CSC 110',
	owner: 'Person A',
	createdAt: new Date(),
	updatedAt: new Date(),
	courseItems: CSC110Items,
	desiredGrade: 75,
};

const CSC225Items: CourseItem[] = [
	{
		id: '87891b7d',
		name: 'Assignment 1',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: { numerator: 7, denominator: 10 },
	},
	{
		id: 'fa7d475e',
		name: 'Assignment 2',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: 75,
	},
	{
		id: '42ad627b',
		name: 'Assignment 3',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: 80,
	},
	{
		id: '285e280f',
		name: 'Assignment 4',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: 85,
	},
	{
		id: '685b49d5',
		name: 'Midterm 1',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 20,
		grade: 60,
	},
	{
		id: '1dbb0e46',
		name: 'Midterm 2',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 20,
		grade: 85,
	},
	{
		id: '846e63a3',
		name: 'Final',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 40,
	},
];

const CSC225: Course = {
	id: 'b6381f69',
	name: 'CSC 225',
	owner: 'Person A',
	createdAt: new Date(),
	updatedAt: new Date(),
	courseItems: CSC225Items,
	desiredGrade: 75,
};

const CSC230Items: CourseItem[] = [
	{
		id: '8763691e',
		name: 'Midterm 1',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 25,
		grade: 40,
	},
	{
		id: '6fb44a5c',
		name: 'Midterm 2',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 25,
		grade: 85,
	},
	{
		id: '18b80bf1',
		name: 'Final',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 50,
	},
];

const CSC230: Course = {
	id: '3910de46',
	name: 'CSC 230',
	owner: 'Person A',
	createdAt: new Date(),
	updatedAt: new Date(),
	courseItems: CSC230Items,
	desiredGrade: 60,
};

const PHYS111Items: CourseItem[] = [
	{
		id: '33yeqkq1',
		name: 'Assignment 1',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: 75,
	},
	{
		id: 'znb1ueec',
		name: 'Assignment 2',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
		grade: 80,
	},
	{
		id: 'b75dzn83',
		name: 'Assignment 3',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
	},
	{
		id: 'zliql2yv',
		name: 'Assignment 4',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 5,
	},
	{
		id: 'w873iby6',
		name: 'Lab 1',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 4,
		grade: 90,
	},
	{
		id: 'acmxcbgp',
		name: 'Lab 2',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 4,
		grade: { numerator: 7, denominator: 9 },
	},
	{
		id: 'jt0a7l6y',
		name: 'Lab 3',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 4,
	},
	{
		id: '4vendf7l',
		name: 'Lab 4',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 4,
	},
	{
		id: 'd1vvm5ka',
		name: 'Lab 5',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 4,
	},
	{
		id: 'fqemoguv',
		name: 'Midterm',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 20,
		grade: { numerator: 13, denominator: 20 },
	},
	{
		id: 'zzqyex7k',
		name: 'Final',
		owner: 'Person A',
		createdAt: new Date(),
		updatedAt: new Date(),
		weight: 40,
	},
];

const PHYS111: Course = {
	id: 'otdl0koo',
	name: 'PHYS 111',
	owner: 'Person A',
	createdAt: new Date(),
	updatedAt: new Date(),
	courseItems: PHYS111Items,
	desiredGrade: 60,
};

export const Fall2020: Semester = {
	id: '3oeg11pf',
	name: 'Fall 2020',
	owner: 'Person A',
	createdAt: new Date(),
	updatedAt: new Date(),
	courses: [MATH100, CSC110, PHYS111],
};

export const Fall2021: Semester = {
	id: 'b84df502',
	name: 'Fall 2021',
	owner: 'Person A',
	createdAt: new Date(),
	updatedAt: new Date(),
	courses: [MATH101, CSC225, CSC230],
};
